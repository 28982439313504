import React from "react";

import Layout from "../../components/Layout";
import FullWidthMedia from "../../components/FullWidthMedia";
import Checkout from "../../components/checkout";

const OudLidPageTemplate = () => {
  const pakket1 = `${process.env.GATSBY_STRIPE_PAKKET_1}`;
  const pakket2 = `${process.env.GATSBY_STRIPE_PAKKET_2}`;

  return (
    <section>
      <div className="columns" style={{ margin: "0" }} id="main">
        <div className="column is-4" style={{ backgroundColor: "#5c5eaa" }}>
          <div className="container content">
            <div className="columns">
              <div className="column is-8 is-offset-2 pakket-list">
                <hr className="solid"></hr>
                <h4 className="title is-size-3 is-uppercase">toegang voor</h4>
                <ul>
                  <li className="is-capitalized theme-body">
                    Lustrum Gala (Incl. Diner) <br /> enkel of duo kaart <br />{" "}
                    <b>12 november 2022</b>{" "}
                  </li>
                  <li className="is-capitalized theme-body">
                    Boks Gala
                    <br /> <b>begin juli 2023</b>{" "}
                  </li>
                  <li className="is-capitalized theme-body">
                    Oud-Leden Dag
                    <br /> <b>begin juli 2023</b>{" "}
                  </li>
                </ul>
                <h4 className="title is-size-3 is-uppercase">items</h4>
                <ul>
                  <li className="is-capitalized theme-body">Lustrum Das</li>
                  <li className="is-capitalized theme-body">
                    Lustrum Magazine
                  </li>
                  <li className="is-capitalized theme-body">Lustrum Almanak</li>
                  <li className="is-capitalized theme-body">
                    Rower Sjaal, 100% Wol
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="column is-8" style={{ backgroundColor: "#00412D" }}>
          <div className="container content">
            <div className="section">
              <div className="columns is-multiline">
                <div className="column is-10">
                  <h2 className="title is-size-2 has-text-weight-bold is-bold-light">
                    €275,- / 400,-
                  </h2>
                  <p className="theme-body">
                    U kunt met dit pakket uzelf van een enkele gala kaart
                    <br /> of een duo gala kaart verzekeren
                  </p>
                  <hr className="solid" />
                  <p className="reason-title is-size-3">
                    KIES UW PAKKET MET EEN ENKEL
                    <br /> OF DUO GALA KAART{" "}
                  </p>
                </div>

                <div className="is-parent column is-4">
                  <Checkout
                    id={pakket1}
                    image_url="https://res.cloudinary.com/mol-meets/image/upload/c_scale,h_700/v1652733905/lustrum/oud-leden-enkel.jpg"
                    text="PAKKET"
                    price="275"
                    heading="enkele gala kaart"
                  />
                </div>
                <div className="is-parent column is-4">
                  <Checkout
                    id={pakket2}
                    image_url="https://res.cloudinary.com/mol-meets/image/upload/c_scale,h_700/v1652733906/lustrum/oud-leden-duo.jpg"
                    text="PAKKET"
                    price="400"
                    heading="duo gala kaart"
                  />
                </div>

                <div className="column is-10">
                  <div className="theme-body">
                    <hr className="solid" id="main"></hr>
                    <p
                      className="title is-size-3"
                      style={{
                        fontFamily: "Athelas",
                        fontStyle: "italic",
                        fontWeight: "500",
                      }}
                    >
                      WAT KAN IK VERWACHTEN VAN DE EVENEMENTEN?
                    </p>
                    <p>
                      Lustrum Gala (12 november 2022):
                      <br />
                      Een begrip. Het traditionele Skadi gala maar dan grootser
                      dan ooit tevoren. Inclusief diner en onbeperkt drank.
                    </p>
                    <p>
                      Boks Gala (begin juli 2023): <br />
                      Het allereerste boks gala in de geschiedenis van de
                      A.R.S.R.! Na een intensieve trainingsperiode zullen een
                      select aantal dappere leden het gevecht aan gaan in de
                      ring. Een onvergetelijk evenement ter ere van het
                      negentiende lustrum. Daar wilt u toch bij zijn?
                    </p>
                    <p>
                      Oud-leden dag (begin juli 2023): <br />
                      Zonder een onvergetelijke oudledendag is het lustrum niet
                      compleet. Het zal een dag worden waar menig oud-lid nog
                      lang over zal napraten. Meer informatie over de datum en
                      invulling volgt later.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const OudLidPage = () => {
  return (
    <Layout navColor="#e3decc">
      <FullWidthMedia
        video={
          "https://res.cloudinary.com/mol-meets/video/upload/q_auto:eco/f_auto/v1653230433/lustrum/Tijdlijn_Oudleden.mp4"
        }
        title={"OUD‑LEDEN"}
        subheading={"lustrum pakket"}
        height={"90vh"}
        color="#e8e100"
        anchor={"/pakketten/oudlid#main"}
      />
      <OudLidPageTemplate />
    </Layout>
  );
};

export default OudLidPage;
