import React from "react";
import { loadStripe } from "@stripe/stripe-js";

const Checkout = ({ id, image_url, text, price, heading }) => {
  let stripePromise;
  const getStripe = () => {
    if (!stripePromise) {
      stripePromise = loadStripe(`${process.env.GATSBY_STRIPE_PUBLIC_KEY}`);
    }
    return stripePromise;
  };

  const redirectToCheckout = async (event) => {
    event.preventDefault();
    const stripe = await getStripe();

    await fetch("/.netlify/functions/stripe", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type",
      },
      body: JSON.stringify({ id: id }),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (session) {
        return stripe.redirectToCheckout({ sessionId: session.id });
      })
      .then(function (result) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, you should display the localized error message to your
        // customer using `error.message`.
        if (result.error) {
          alert(result.error.message);
        }
      })
      .catch(function (error) {
        console.error("Error:", error);
      });
  };

  return (
    // <button
    //   disabled={loading}
    //   style={
    //     loading ? { ...buttonStyles, ...buttonDisabledStyles } : buttonStyles
    //   }
    //   onClick={redirectToCheckout}
    // >
    //   {text} {price}
    // </button>
    <div role="button" disabled>
      <article
        className={`blog-list-item tile is-child`}
        style={{ padding: 0 }}
      >
        <header>
          <div className="featured-thumbnail">
            <div className="featured-image">
              <img alt="pakket" src={image_url} />
            </div>
            <div className="featured-text closed">
              <div className="featured-text-closed">gesloten</div>€{price},-
            </div>
          </div>
          <p className="post-meta">
            <span className="title is-size-2">{text}</span>
            <span className="subtitle is-size-3 is-block">{heading}</span>
          </p>
        </header>
      </article>
    </div>
  );
};
export default Checkout;
